import 'react-app-polyfill/stable';
import "core-js/stable";
import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getPerformance } from 'firebase/performance';
import { BrowserRouter, Route } from "react-router-dom";
import App from "./App";

if(!!window && !!window.location && !!window.location.href && !(window.location.href.includes("localhost") || window.location.href.includes("dev") || window.location.href.includes("qa"))) {
	Sentry.init({
		dsn: process.env.REACT_APP_PROD_FE_GENIE_SENTRY,
		integrations: [new Integrations.BrowserTracing()],
		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: 1.0,
	});
}


document.addEventListener("DOMContentLoaded", e => {
	ReactDOM.render(
        <BrowserRouter>
            <Route path="/" component={App} />
        </BrowserRouter>,
        document.getElementById("main"),
    );
});

window.addEventListener("beforeinstallprompt", function(event) {
	event.preventDefault();
});
