import React from 'react';

export default class Loader extends React.Component{
	state = {
		isMobile: (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1)
	}
	render() {
		let {isMobile} = this.state, { position } = this.props;
		return (
			<div className={`${position ? position : "pF"} bgF wp100 hp100 t0 l0 bgF z20 ghost-card-pulsate-grey`}></div>
		);
	}
}
