import axios from "axios";
import { logEvent } from "firebase/analytics"

const _getLsValues = () => {
  if(!!window && !!window.localStorage) {
    let k = {storage: window.localStorage};
    window.localStorage.token  && (k.token = window.localStorage.token);
    window.localStorage.role  && (k.role = window.localStorage.role);
    window.localStorage.roles  && (k.roles = window.localStorage.roles);
    window.localStorage.user_id  && (k.user_id = window.localStorage.user_id);
    window.localStorage.user_name  && (k.user_name = window.localStorage.user_name);
    window.localStorage.is_tnc_accepted  && (k.is_tnc_accepted = window.localStorage.is_tnc_accepted);
    window.localStorage.is_tnc_mail_sent  && (k.is_tnc_mail_sent = window.localStorage.is_tnc_mail_sent);
    window.localStorage.is_tnc_page_visble  && (k.is_tnc_page_visible = window.localStorage.is_tnc_page_visble);
    window.localStorage.user && JSON.parse(window.localStorage.user) && typeof(JSON.parse(window.localStorage.user)) === "object" && (k.user = JSON.parse(window.localStorage.user));
    return k;
  }
  return {};
}

const _buildFormData = (obj = {}) => {
  let form_data = new FormData();
  if(!!obj && typeof(obj) === "object") {
    Object.keys(obj).map(prop => {
      form_data.append(prop, obj[prop]);
    });
  }
  return form_data;
}

const _resetErrors = (form) => {
  form = [...form];
  form.map((div,i) => {
    // form[i] = {...form[i]};
    // form[i].inputs = [...form[i].inputs];
    form[i].inputs.map((inp,j) => {
      // form[i].inputs[j] = {...form[i].inputs[j]};
      form[i].inputs[j].error = "";
    });
  });
  return form;
}

const _errorHandlerForms = (init_form = []) => {
  let form = [..._resetErrors(init_form)], hasError = false;
  form.map((div,divIdx) => {
    // form[divIdx] = {...form[divIdx]};
    // form[divIdx].inputs = [...form[divIdx].inputs];
    form[divIdx].inputs.map((inpOrig,inpIdx) => {
      // let inp = {...form[divIdx].inputs[inpIdx]};
      let inp = form[divIdx].inputs[inpIdx];
      if(inp.inputType === 'text') {
        inp.value = inp.value && typeof(inp.value) === "string" && inp.value.trim();
        if(inp.required) {
          if(!inp.value || inp.value.length === 0) {
            inp.error = inp?.errorMsg ? inp.errorMsg  : `Required field`;
          }
        }
        if(inp.minLength) {
          if(!!inp.required || inp.value !== "") {
            if(inp.value.length < inp.minLength) {
              inp.error = inp?.errorMsgMinLength ? inp.errorMsgMinLength  : `Field should be minimum ${inp.minLength} characters long`;
            }
          }
        }
        if(inp.maxLength) {
          if(!!inp.required || inp.value !== "") {
            if(inp.value.length > inp.maxLength) {
              inp.error = inp?.errorMsgMaxLength ? inp.errorMsgMaxLength  : `Field should be maximum ${inp.maxLength} characters long`;
            }
          }
        }
        if(inp.regex && inp.regex !== '') {
          if(!!inp.required || inp.value !== "") {
            let patrn = new RegExp(inp.regex);
            if(!patrn.test(inp.value)) {
              inp.error = inp?.errorMsgRegex ? inp.errorMsgRegex : `Invalid value for field`;
            }
          }
        }
      } else if (inp.inputType === 'select') {
        if(!inp.value && inp.required) {
          // console.log("error select called found");
          inp.error = inp?.errorMsg ? inp.errorMsg  : `Select a value`;
        }
        if(inp.isMulti && Array.isArray(inp.value) && inp.required && inp.value.length <= 0) {
          inp.error = `Select atleast one value`;
        }
      } else if (inp.inputType === "date-picker") {
        if(!inp.value && inp.required) {
          // console.log("error select called found");
          inp.error = `Select a value`;
        }
      } else if (inp.inputType === "boolean") {
        if(typeof(inp.value) !== "boolean") {
          inp.error = `Input type value is not a boolean`;
        }
      } else if (inp.inputType === "file-upload") {
        if(inp.required && (!inp.title || !inp.file)) {
          inp.error = `File Upload is mandatory`;
        }
      }
      if(inp.error !== "") hasError = true;
      form[divIdx].inputs[inpIdx] = inp;
    })
  });
  return { form, hasError }
}

const _onChange = ({e,inpID,divIdx,inpIdx,type,form,attr="value"}) => {
  if(type === 'text' && !!e && e.target){
    if(e.target && e.target.getAttribute("data-pattern")){
      let patrn = new RegExp(e.target.getAttribute("data-pattern")),
      div = parseInt(e.target.getAttribute("data-div")),
      inp = parseInt(e.target.getAttribute("data-inp")),
      skipInpattern = e.target.getAttribute("data-skipinpattern");
      if(patrn.test(e.target.value) || e.target.value === "" || !!form[div]["inputs"][inp].skipInputPattern) {
        form[div]["inputs"][inp]["value"] = form[div]["inputs"][inp].uppercase ? e.target.value.toUpperCase() : e.target.value;
      }
    }
  } else if (type === 'react-select' || type === 'react-select-create') {
    form[divIdx]["inputs"][inpIdx][attr] = e;
    if(attr === "value") form[divIdx]["inputs"][inpIdx]["inputValue"] = "";
  } else if (type === 'boolean') {
    form[divIdx]["inputs"][inpIdx]["value"] = !form[divIdx]["inputs"][inpIdx]["value"];
  } else if (type === "date-picker") {
    form[divIdx]["inputs"][inpIdx].value = e;
  } else if (type === "file-upload") {
    const fileList = e.target.files;
    (!!fileList && fileList.length > 0) && (form[divIdx]["inputs"][inpIdx] = _readFileAndSaveObj(fileList[0], form[divIdx]["inputs"][inpIdx]));
  } else if (type === "checkbox" && !!e) {
    let value = form[divIdx].inputs[inpIdx].value;
    if(!form[divIdx].inputs[inpIdx].isMulti) {
      for(let i = 0; i < value.length; i++) {
        if(value[i].value === e.value) {
          form[divIdx].inputs[inpIdx].value = [];
          return form;
        }
      }
      form[divIdx].inputs[inpIdx].value = [e];
    } else {
      for(let i = 0; i < value.length; i++) {
        if(value[i].value === e.value) {
          form[divIdx].inputs[inpIdx].value.splice(value.indexOf(e), 1);
          return form;
        }
      }
      form[divIdx].inputs[inpIdx].value.push(e);
    }
  }
  return form;
}

const _onBlurFunc = ({e,inpID,divIdx,inpIdx,type,form}) => {
  if(type === 'text') {
    form[divIdx].inputs[inpIdx].error = '';
    form[divIdx].inputs[inpIdx].value = form[divIdx].inputs[inpIdx].value && typeof(form[divIdx].inputs[inpIdx].value) === "string" && form[divIdx].inputs[inpIdx].value.trim();
    if(form[divIdx].inputs[inpIdx].value !== "") {
      if(form[divIdx].inputs[inpIdx].required) {
        if(!form[divIdx].inputs[inpIdx].value || form[divIdx].inputs[inpIdx].value.length === 0) {
          form[divIdx].inputs[inpIdx].error = `Required field`;
        }
      }
      if(form[divIdx].inputs[inpIdx].minLength) {
        if(form[divIdx].inputs[inpIdx].value.length < form[divIdx].inputs[inpIdx].minLength) {
          form[divIdx].inputs[inpIdx].error = `Field should be minimum ${form[divIdx].inputs[inpIdx].minLength} characters long`;
        }
      }
      if(form[divIdx].inputs[inpIdx].maxLength) {
        if(form[divIdx].inputs[inpIdx].value.length > form[divIdx].inputs[inpIdx].maxLength) {
          form[divIdx].inputs[inpIdx].error = `Field should be maximum ${form[divIdx].inputs[inpIdx].maxLength} characters long`;
        }
      }
      if(form[divIdx].inputs[inpIdx].regex && form[divIdx].inputs[inpIdx].regex !== '') {
        let patrn = new RegExp(form[divIdx].inputs[inpIdx].regex);
        if(!patrn.test(form[divIdx].inputs[inpIdx].value)) {
          form[divIdx].inputs[inpIdx].error = `Invalid value for field`;
        }
      }
    }
  } else if(type === "react-select" || type === "react-select-create") {
    form[divIdx].inputs[inpIdx].error = '';
  } else if (type === "date-picker") {
    form[divIdx].inputs[inpIdx].error = '';
  }
  return form;
}

const _readFileAndSaveObj = (file, formData) => {
  let format = file.type.split('/');
  format = (Array.isArray(format) && (format.length > 1)) ? (format[1]) : null;
  if(!!format) {
    const name = file.name.replace(/s+/g, "_").replace(/[^A-Za-z0-9_]+/g, ''), 
    title = `${name}_${_exportDateISO(new Date())}.${format}`;
    formData.title = title;
    formData.file = new File([file], title, { type: file.type });
    formData.error = "";
    return formData;
  }
  return null;
}

const _buildParams = ({ form }) => {
  if(!!form) {
    let params = {};
    for(let i = 0; i < form.length; i++) {
      for(let j = 0; j < form[i].inputs.length; j++) {
        if(form[i].inputs[j].inputType === 'text') {
          params[form[i].inputs[j].paramKey] = !!form[i].inputs[j].value ? form[i].inputs[j].value : null;
        } else if(form[i].inputs[j].inputType === 'select') {
          params[form[i].inputs[j].paramKey] = !!form[i].inputs[j].value && !!form[i].inputs[j].value.value ? (typeof(form[i].inputs[j].value.value) === "string" ? form[i].inputs[j].value.value.split('$$')[0] : form[i].inputs[j].value.value) : null;
        }
      }
    }
    return params;
  }
}

const _capitalizeString = (str) => {
	if(!!str) {
		str = str.toLowerCase();
    str = str.trim();
		let cap_str = "";
		str.split(" ").map(word => {
			if(word.length > 0) {
				cap_str += `${word.charAt(0).toUpperCase()}${word.slice(1)} `;
			}
		});
		return cap_str.trim();
	} else {
		return "";
	}
}

const _exportDateISO = (date) => {
  return new Date(new Date(date).getTime() + 19800000).toISOString().substring(0, 10);
}

const _queryParamGenerator = (params) => {
  return Object.keys(params).map((key) => {
    return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
  }).join('&');
}

const _dateStringFormatted = (date) => {
  let date_string = "";
  if(!!date && date.toDateString) {
    date_string = date.toDateString();
  } else {
    date_string = date && new Date(date) && new Date(date).toDateString();
  }
  if(date_string && typeof(date_string) === "string" &&  date_string.split(' ').length === 4) {
    let date_arr = date_string.split(' ');
    return [date_arr[2], date_arr[1], date_arr[3].substring(2)].join(' ');
  } else {
    return "NA";
  }
}

const _getApiResponseWithSearch = ({api,apiType,baseUrlType,searchTerm="",searchKey,size=10,page=1,params}) => {
  try{
    return axios[apiType](`${_getBaseUrl(baseUrlType)}${api}?${searchKey}=${searchTerm}&size=${size}&page=${page}`, { ..._getConfig(), params });
  }catch(error){
    return new Promise((resolve, reject) => { reject('NO VALUE FOUND FOR ' + api);});
  }
}

const _postApiResponseWithSearch = ({api,apiType,baseUrlType,size=10,page=1,body}) => {
  try{
    return axios[apiType](`${_getBaseUrl(baseUrlType)}${api}?size=${size}&page=${page}`, body, _getConfig(true));
  }catch(error){
    return new Promise((resolve, reject) => { reject('NO VALUE FOUND FOR ' + api + " error-" + error);});
  }
}

const _numberWithCommas = (num) => {
  if(!!num) {
    num = parseFloat(num);
    return !isNaN(num) ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "NA";
  }
  return "NA";
}

const _inView =  (element,ctop,cleft,cbottom,cright) => {
  var element = element,
      elementRect = element.getBoundingClientRect(),
      eHeight = elementRect.height,
      eWidth = elementRect.width,
      dHeight = document.innerHeight || document.documentElement.clientHeight,
      dWidth = document.innerWidth || document.documentElement.clientWidth,
      ctop = ctop || 64,
      cleft = cleft || 64,
      cbottom = cbottom || (eHeight + 2*dHeight), 
      cright = cright ||  (eWidth + 2*dWidth);
      
  return {
    complete: (
                elementRect.top >= (0 - ctop) &&
                elementRect.left >= (0 - cleft) &&
                elementRect.bottom <= (eHeight + dHeight) &&
                elementRect.right <= (eWidth + dWidth)
              ), 
    partial:  (
                elementRect.top >= (0 - eHeight - dHeight) &&
                elementRect.left >= (0 - eWidth - dWidth) &&
                elementRect.bottom <= (eHeight + 3.2*dHeight) &&
                elementRect.right <= (eWidth + 1.2*dWidth)
              ),
    custom: (
                elementRect.top >= (ctop) &&
                elementRect.left >= (cleft) &&
                elementRect.bottom <= (cbottom) &&
                elementRect.right <= (cright)
              )
  };
}

const _debounce = (func, delay) => {
  let dbTimer;
  return function () {
      const con = this, arg = arguments;
      clearTimeout(dbTimer);
      dbTimer = setTimeout(() => { func.apply(con, arg) }, delay)
  }
}

const _lazyloadImages = () => {
  let images = document.querySelectorAll(".image[src='/1.png']");
  for(let k =0; k<images.length; k++){
      _inView(images[k]).partial && (images[k].setAttribute("src",images[k].getAttribute("data-src")));
  }
}

const _getBaseUrl = (type = 'cataloguing') => {
  let mapping = {
    goodcat : ["goodcatalog", "goodcatalog-dev", "goodcatalog-qa"],
    dev : ["portal", "devportal", "qaportal"],
    cataloguing : ["cataloguing", "cataloguing-dev", "cataloguing-qa"],
    ums : ["ums","ums-dev", "ums-qa"],
    oms : ["oms","oms-dev","oms-qa"],
    ims : ["ims","ims-dev","ims-qa"],
    lms : ["lms","lms-dev","lms-qa"],
    notes : ["notes","notes-dev","notes-qa"],
    events : ["events","events-dev","events-qa"],
    tag : ["tagging", "tagging-dev", "tagging-qa"]
  };
  let retValue = mapping[type][0];
  if (window.location.href.includes("dev") || window.location.href.includes("//localhost:") || window.location.href.includes("192.168.")){
    retValue = mapping[type][1];
    if(window.localStorage[type]) return window.localStorage[type];
  } else if(window.location.href.includes("qa")) {
    retValue = mapping[type][2];
  }
  return "https://"+retValue+".com";
}

const _isDevLink = () => !!(window.location.href.includes("dev") || window.location.href.includes("//localhost:") || window.location.href.includes("192.168."));

const _isQaLink = () => !!(window.location.href.includes("qa"));

const _mountCancelController = (config) => {
  let ignoredApis = ['/api/v1/cart','/api/v1/auth','/api/v1/event','/api/v1/sales-agent/resting-place', "/api/v1/sales-agent/carts","/api/v1/skus/elastic-search/"];
  ignoredApis = ignoredApis.filter(url => config.url.includes(url));
  return !ignoredApis.length;
}

const _axiosIntercept = (axios) => {
  window.abortControllers = {};
  axios.interceptors.request.use((config) => {
      // Do something before request is sent
      if(navigator.onLine){
        document.getElementById("offLine")?.style?.display && (document.getElementById("offLine").style.display = "none");
      }else{
        document.getElementById("offLine")?.style?.display && (document.getElementById("offLine").style.display = "block");
      }
      return _mountCancelController(config) ? _cancelAbortController(axios, config) : config;
  }, (error) => {
      // Do something with request error
      return Promise.reject(error);
  });

  axios.interceptors.response.use(response => {
    _removeAbortController(response);
      return response;
  }, error => {
    _removeAbortController(error && error.response);
      // if (axios.isCancel(error)) {
      //     console.log('Request cancelled', error); // Can be used later hence leaving this here
      // }
      if (error && error.response && error.response.status && error.response.status === 401) {
        const skuRegex = /GM-[0-9]{4}-[0-9]{7,16}/gm, href = window.location.href, params = new URLSearchParams(href);
        if(href.includes("/product-detail/") && params.get('catalog_id') && href.match(skuRegex)){
          window.location = window.location.origin + `/fair_landing?catalog_id=${params.get('catalog_id')}&sku_id=${href.match(skuRegex)}`;
        }else{
          if(!(error.response && error.response.config && error.response.config.url && (error.response.config.url.includes("/api/auth/login") || error.response.config.url.includes("/auth/validate") || error.response.config.url.includes("/api/v1/cart") || error.response.config.url.includes("/api/v1/sales-agent/carts")))){
              window.localStorage.clear();
              window.location = window.location.origin + "/signin?redirect_url="+encodeURIComponent(window.location.href);
          }
        }         
      }
      return Promise.reject(error);
  });
}

const _cancelAbortController = (axios, config) => {
  const CancelToken = axios.CancelToken,
          source = CancelToken.source(),
          pathname = config.url.split('?')[0];
  window.abortControllers &&
  window.abortControllers[pathname] &&
  window.abortControllers[pathname].cancel &&
  window.abortControllers[pathname].cancel("Repeat request cancelled");
  config = { ...config, cancelToken: source.token }
  window.abortControllers[pathname] = source;
  return config;
}

const _removeAbortController = (response) => {
  response && response.config && window.abortControllers && (window.abortControllers[response.config.url.split('?')[0]] = null);
}

const _parseJwt = (token) => {
	let base64Url = token.split('.')[1],
  base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/'),
  jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
	}).join(''));
	return JSON.parse(jsonPayload);
};

const _getUserData = () => {
    let localStorage = _getLsValues(), user_data = {};
    user_data = (!!localStorage.token) ? _parseJwt(localStorage.token) : {};
    return user_data;
}

const _getUserPriorityRole = () => {
    let retRole = null, localStorage = _getLsValues(), user_data = {};
    user_data = (!!localStorage.token) ? _parseJwt(localStorage.token) : {};
    let roles = user_data.roles;
    retRole = _getRolePriority(roles)
    // return "client";
    return retRole;
}

const _getRolePriority = (roles) => {
  if (!!roles) {
		let rolesMapper = ["admin", "Sales Agent", "rm", "client", "manufacturer", "qc" ,"intern", "View_TnC"];
		for (let i = 0; i < rolesMapper.length; i++) {
			if (roles && roles.includes(rolesMapper[i])) {
				return rolesMapper[i].toLowerCase();
			}
			for (let j = 0; j < (roles && roles.length ? roles.length : 0); j++) {
				if (roles[j].toLowerCase().includes("testing") && roles[j].toLowerCase().includes(rolesMapper[i])) {
					return rolesMapper[i].toLowerCase();
				}
			}
		}
	}
	return null;
}

const _setCookieValue = (key, value, time_offset) => {
  document.cookie = `${key}=${value}; expires=${new Date(new Date().getTime() + time_offset).toUTCString()} ; path=/`;
}

const _getCookieValue = (name) => {
	let nameEQ = name + "=", ca = document.cookie.split(';');
    for(let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

const _getConfig = (is_x_token = false, sendLocation = false) => {
    if(is_x_token) {
        return {
            headers: {
                "x-access-token": (!!_getLsValues() && !!_getLsValues().token) ? _getLsValues().token.toString() : ""
            }
        };
    }

    let headers = {
      authorization: (!!_getLsValues() && !!_getLsValues().token) ? 'Bearer ' + _getLsValues().token.toString() : '',
    }
    !!sendLocation && (headers = {...headers, ..._getOrSetIpLocation()});
    return {
      headers
    }
}

const _getLogData = () => {
    let ts = new Date(),
    user_data = _getUserData();
    return {
      timestamp: ts.toISOString(), 
      epoch: ts.getTime(), 
      href: window && window.location && window.location.href,
      baseUrl: window && window.location && window.location.hostname,
      pathname: window && window.location && window.location.pathname,
      search: window && window.location && window.location.search,
      ...user_data
    }
}

const _closeAlert = (passedThis = null) => {
  if(!!passedThis) {
    let alert = {
      display: false,
      message: "",
      greenbutton: "",
      redbutton: "",
      greenfunction: null,
      redfunction: null,
    }
    passedThis && passedThis.setState && passedThis.setState({ alert });
    return alert;
  }
  return "Pass this reference";
}

const _errorAlert = (passedThis = null, message = "") => {
  if(!!passedThis) {
    let alert = {
      display: true,
      message,
      greenbutton: "",
      redbutton: "Close",
      greenfunction: null,
      redfunction: () => _closeAlert(passedThis),
    }
    passedThis && passedThis.setState && passedThis.setState({ alert });
    return alert;
  }
  return "Pass this reference";
}

const _infiniteScrollListener = ({ id = null, passedThis = null, callbackFunc = null }) => {
	if (!!id && !!passedThis && !!callbackFunc && typeof callbackFunc === "function") {
		document.getElementById(id) && document.getElementById(id).addEventListener(
			"scroll",
      _debounce(e => {
        _infiniteLoad({passedThis, callbackFunc});
      }, 50)
		);
    document.getElementById(id) && document.getElementById(id).setAttribute("data-scroll","true");
	}
};

const _infiniteLoad = ({passedThis = null, callbackFunc = null}) => {
  if (!!passedThis && !!callbackFunc && typeof callbackFunc === "function") {
    var loadMore = document.getElementById("loadMore");
    loadMore && _inView(loadMore).partial && _loadMore({ passedThis, callbackFunc }) && console.log("_infiniteLoad");
    _lazyloadImages();
  }
}

const _loadMore = ({ passedThis = null, callbackFunc = null }) => {
	if (!!passedThis && !!callbackFunc && typeof callbackFunc === "function") {
		const { loadingMore, hasMore } = passedThis.state;
		if (!loadingMore && !!hasMore) {
			passedThis.setState({ loadingMore: true }, callbackFunc);
		}
	}
};

const _timedPopup = ({passedThis = null, callbackFunc = null, display = true, message = '', error = false, time = 3000}) => {
  if (!!passedThis) {
    let { popup } = passedThis.state;
    (popup.timer) && clearTimeout(popup.timer);
    popup.timer = setTimeout(() => passedThis.setState({popup: {...popup, display: false}}), time);
    popup = {...popup, display, message, error};
    passedThis.setState({ popup }, callbackFunc);
  }
}
const _pageExitTime =({passedThis = null},page) =>{
  let initialTime = passedThis.state.dateValue && passedThis.state.dateValue;
  let totalTime= ((new Date().getTime() - initialTime) /1000) + " Sec";
  
  _universal_logger(page + " Exit",{"Leaving Epoch":new Date().getTime(),
  "TimeSpent":totalTime,
  "Initial Time Epoch":initialTime,
  "IOSapp": !!localStorage.getItem("inApp"),
	"AndroidApp":!!localStorage.getItem("inAnroidApp")
  });
//   window.webengage && window.webengage.track && window.webengage.track(page + " Exit",{"Leaving Epoch":new Date().getTime(),
//   "TimeSpent":totalTime,
//   "Initial Time Epoch":initialTime
// });
};
const _pageEnterTime=({passedThis = null},page) =>{
  passedThis.setState({
    dateValue:new Date().getTime()
  })
  // window.webengage && window.webengage.track && window.webengage.track( page ,{"Initial Time Epoch":new Date().getTime()});
  _universal_logger(page ,{"Initial Time Epoch":new Date().getTime(), "IOSapp": !!localStorage.getItem("inApp"),"AndroidApp":!!localStorage.getItem("inAnroidApp")});
}

const _isJsonString = (str) => {
  try {
      JSON.parse(str);
  } catch (e) {
      return false;
  }
  return true;
}

const _existAndGetLsValue = (keys = []) => {
	if (typeof window.localStorage !== "undefined") {
		let obj = {};
		if(keys && typeof(keys) === "string") {
			if(window.localStorage.hasOwnProperty(keys)) {
				obj[keys] = window.localStorage[keys];
			}
		} else if (keys && Array.isArray(keys) && keys.length) {
			for(let i = 0; i < keys.length; i++) {
				if(window.localStorage.hasOwnProperty(keys[i]))
				obj[keys[i]] = window.localStorage[keys[i]];
			}
		}
		return obj;
	}
}

const _setLsValues = (ls_obj = {}) => {
	if (typeof window.localStorage !== "undefined") {
		for(let i = 0; i < Object.keys(ls_obj).length; i++) {
			window.localStorage[Object.keys(ls_obj)[i]] = ls_obj[Object.keys(ls_obj)[i]];
		}
		return window.localStorage;
	}
	return {};
}

const _getOrSetIpLocation = (checkNow = false) => {
	if((_existAndGetLsValue("ipLocation") && _existAndGetLsValue("ipLocation").ipLocation) && !checkNow) {
		return _isJsonString(_existAndGetLsValue("ipLocation").ipLocation) ? JSON.parse(_existAndGetLsValue("ipLocation").ipLocation) : _existAndGetLsValue("ipLocation").ipLocation;
	} else {
		axios.get('https://api.db-ip.com/v2/free/self')
		.then( response => {
			let geoLocation = JSON.stringify(response.data);
      try{
        geoLocation = geoLocation.replace(/[^\x00-\x7F]/g, "");
        _setLsValues({ ipLocation: geoLocation });
      }catch(error){
        console.log("_setLsValues error",error);
      }
			
		})
		.catch(error => {
			console.error(error);
		})
		return {};
	}
}


const _sanitizeWEevent = (obj = null) => {
	try {
		if(obj && typeof(obj) === "object") {
			let keys = Object.keys(obj);
			for(let i = 0; i < keys.length; i++) {
				if(obj[keys[i]] === null || obj[keys[i]] === undefined || (typeof(obj[keys[i]]) === "string" && obj[keys[i]].trim() === "")) {
					obj[keys[i]] = null;
				}
				if(typeof(obj[keys[i]]) === "object") {
					obj[keys[i]] = _sanitizeWEevent(obj[keys[i]]);
				}
			}
			return obj;
		} else {
			return null;
		}
	} catch (error) {
		console.error(error);
		return obj;
	}
}

const _logBackendEvent = async ({userId = null, userName = null, phone = null, eventName = null, businessDataObj = {}, entityTypeObj = {}, triggerInfoObj = {}, deviceInfoObj = {}, sourceObj = {}, send_to_we = "false", passedThis}) => {
	try {
		if(!eventName) throw new Error("Required field not passed");
		let ts = new Date(), time_spent = "NA";
		if(passedThis && passedThis.state && passedThis.state.dateValue) time_spent = (new Date().getTime() - (passedThis.state.dateValue)) / 1000;
		let event_data = {
			userId: _getUserData && _getUserData() && _getUserData().userId ? _getUserData().userId : (userId ? userId : (_getCookieValue('_ga') ? _getCookieValue('_ga') : new Date().getTime())), 
			userName: _getUserData && _getUserData() && _getUserData().username ? _getUserData().username : (userName ? userName : (_getCookieValue('_ga') ? _getCookieValue('_ga') : new Date().getTime())),
			phone, 
			eventName,
			businessDataObj: {
				time_spent,
				...businessDataObj
			}, 
			entityTypeObj: {
				priority_role: _getUserPriorityRole(),
				...entityTypeObj
			}, 
			triggerInfoObj: {
				type: "frontend",
				repo: "EastAssociatesReact",
				Class: "NA",
				function: "NA",
				...triggerInfoObj,
			}, 
			deviceInfoObj: {
				userAgent: navigator.userAgent,
				appName: navigator.appName,
				platform: navigator.platform,
				vendor: navigator.vendor,
				NetworkInformation: navigator.connection,
				userAgentData: navigator.userAgentData,
				language: navigator.language,
				allLanguage: navigator.languages,
				deviceMemory: navigator.deviceMemory,
				maxTouchPoints: navigator.maxTouchPoints,
				online: navigator.onLine,
				location: _getOrSetIpLocation(),
				...deviceInfoObj,
			}, 
			sourceObj: {
				product: "EastAssociates",
				timestamp: ts.toISOString(),
				epoch: ts.getTime(),
				time_spent,
				href: window && window.location && window.location.href,
				baseUrl: window && window.location && window.location.hostname,
				pathname: window && window.location && window.location.pathname,
				search: window && window.location && window.location.search,
				...sourceObj,
			}
		};
		event_data = _sanitizeWEevent(event_data);
		let response = await axios.post(`${_getBaseUrl('events')}/api/v1/event`,
		{
			event: event_data,
			username: "frontend",
			password: "EastAssociates@123456",
			send_to_we
		}
		, _getConfig());
	} catch (error) {
		console.error("Unable to log data to backend >>> ", error);
	}
}

const _universal_logger = (event_name, event_params, logFirebase = true, logWE = true) => {
  if((typeof event_name == 'string') && (typeof event_name!='undefined') && (typeof event_params == 'object') && (typeof event_params!='undefined')) {
    if(window.firebaseAnalytics && logFirebase) {
      logEvent(window.firebaseAnalytics, event_name, event_params);
    }
    if(logWE && window.webengage) {
      window.webengage.track(event_name, event_params);
    }
  }
  else
    return false;
}

const _universal_logger_v2 = async ({ eventName, entity_name, entity_id, page, type, element, error, sub_entity_name, sub_entity_id, source = "", scroll_depth = "", logFirebase = true, logWE = true, logBackend = true, userId = null, userName = null, phone = null, businessDataObj = {}, entityTypeObj = {}, triggerInfoObj = {}, deviceInfoObj = {}, sourceObj = {}, send_to_we = "true", passedThis }) => {
  try {
      if (!eventName) throw new Error("Required field not passed");
      if (!type) type = eventName;
      let ts = new Date(),
          time_spent = "",
          userData = _getUserData && _getUserData(),
          userPriorityRole = _getUserPriorityRole && _getUserPriorityRole(),
          ipLocation = _getOrSetIpLocation && _getOrSetIpLocation();
      if (passedThis && passedThis.state && passedThis?.state?.dateValue) time_spent = (new Date().getTime() - passedThis.state.dateValue) / 1000;
      if (eventName === "view" && passedThis && !passedThis?.state?.dateValue) passedThis.setState({ dateValue: ts.getTime() });
      let event_data = {
          userId: userData?.userId ? userData?.userId : userId ? userId : _getCookieValue("_ga") ? _getCookieValue("_ga") : new Date().getTime(),
          userName: userData?.username ? userData?.username : userName ? userName : _getCookieValue("_ga") ? _getCookieValue("_ga") : new Date().getTime(),
          phone,
          eventName,
          businessDataObj: {
              time_spent,
              ...businessDataObj,
          },
          entityTypeObj: {
              priority_role: userPriorityRole,
              ...userData,
              ...entityTypeObj,
          },
          triggerInfoObj: {
              platform: "frontend",
              repo: "EastAssociatesReact",
              Class: "NA",
              function: "NA",
              page,
              type,
              entity_name,
              entity_id,
              sub_entity_name,
              sub_entity_id,
              element,
              error,
              source,
              ...triggerInfoObj,
          },
          deviceInfoObj: {
              userAgent: navigator && navigator?.userAgent,
              appName: navigator && navigator?.appName,
              platform: navigator && navigator?.platform,
              vendor: navigator && navigator?.vendor,
              NetworkInformation: navigator && navigator?.connection,
              userAgentData: navigator && navigator?.userAgentData,
              language: navigator && navigator?.language,
              allLanguage: navigator && navigator?.languages,
              deviceMemory: navigator && navigator?.deviceMemory,
              maxTouchPoints: navigator && navigator?.maxTouchPoints,
              online: navigator && navigator?.onLine,
              location: _getOrSetIpLocation(),
              ios_app: !!localStorage && !!localStorage.getItem("inApp"),
              android_app: !!localStorage && !!localStorage.getItem("inAnroidApp"),
              ...deviceInfoObj,
          },
          sourceObj: {
              product: "EastAssociates",
              timestamp: ts.toISOString(),
              epoch: ts.getTime(),
              href: window && window.location && window?.location?.href,
              baseUrl: window && window.location && window?.location?.hostname,
              pathname: window && window.location && window?.location?.pathname,
              search: window && window.location && window?.location?.search,
              ...sourceObj,
          },
      };
      let ga_event_data = {
          ...businessDataObj,
          userId: userData?.userId ? userData?.userId : userId ? userId : _getCookieValue("_ga") ? _getCookieValue("_ga") : new Date().getTime(),
          userName: userData?.username ? userData?.username : userName ? userName : _getCookieValue("_ga") ? _getCookieValue("_ga") : new Date().getTime(),
          phone,
          eventName,
          page,
          type,
          element,
          source,
          first_name: userData?.firstName ? userData?.firstName : "",
          last_name: userData?.lastName ? userData?.lastName : "",
          roles: userData?.roles && Array.isArray(userData?.roles) && userData?.roles.length > 0 ? userData?.roles.sort().join(";") : "",
          groups: userData?.groups && Array.isArray(userData?.groups) && userData?.groups.length > 0 ? userData?.groups.sort().join(";") : "",
          piority_role: userPriorityRole,
          merchandiser: userData?.roles ? userData?.roles.includes("rm") || userData?.roles.includes("ops_rm") : "",
          buyer: userData?.roles ? userData?.roles.includes("buyer") : "",
          factory: userData?.roles ? userData?.roles.includes("manufacturer") : "",
          admin: userData?.roles ? userData?.roles.includes("admin") : "",
          designer: userData?.roles ? userData?.roles.includes("designer") : "",
          vp: userData?.roles ? userData?.roles.includes("vp") : "",
          visit_time: passedThis?.state?.dateValue ? new Date(passedThis?.state?.dateValue).getTime() : new Date(ts).getTime(),
          leave_time: passedThis?.state?.dateValue ? new Date(ts).getTime() : "NA",
          timestamp: ts.toISOString(),
          epoch: ts.getTime(),
          time_spent,
          scroll_depth,
          href: window && window.location && window?.location?.href,
          baseUrl: window && window.location && window?.location?.hostname,
          pathname: window && window.location && window?.location?.pathname,
          search: window && window.location && window?.location?.search,
          location_ip: ipLocation?.ipAddress,
          location_city: ipLocation?.city,
          location_state: ipLocation?.stateProv,
          location_country: ipLocation?.countryName,
          location_continent: ipLocation?.continentName,
          userAgent: navigator && navigator?.userAgent,
          network_downlink: navigator && navigator?.connection?.downlink,
          network_info: navigator && navigator?.connection?.effectiveType,
          language: navigator && navigator?.language,
          _ga: _getCookieValue("_ga"),
          entity_name,
          entity_id,
          ios_app: !!localStorage && !!localStorage.getItem("inApp"),
          android_app: !!localStorage && !!localStorage.getItem("inAnroidApp"),
      };
      event_data = _sanitizeWEevent(event_data);
      ga_event_data = _sanitizeWEevent(ga_event_data);
      if (logBackend) {
          let response = await axios.post(
              `${_getBaseUrl("events")}/api/v1/event`,
              {
                  event: event_data,
                  username: "frontend",
                  password: "EastAssociates@123456",
                  send_to_we,
              },
              _getConfig(),
          );
          console.log("logging backend event ");
      }
      if (window.firebaseAnalytics && logFirebase) {
          logEvent(window.firebaseAnalytics, eventName, ga_event_data);
          console.log("logging firebase event ");
      }
      if (window.webengage && logWE) {
          window.webengage.track(eventName, _sanitizeWEevent(event_data));
          console.log("logging webengage event ", _sanitizeWEevent(event_data));
      }
  } catch (error) {
      console.error("Unable to log data >>> ", error);
  }
};

const _findIdxById = (id, form) => {
  if(!id || !form) return {divIdx: null, inpIdx: null};
  for(let divIdx = 0; divIdx < form.length; divIdx++) {
    for(let inpIdx = 0; inpIdx < form[divIdx].inputs.length; inpIdx++) {
      if(form[divIdx].inputs[inpIdx].id === id) return {divIdx, inpIdx};
    }
  }
  return {divIdx: null, inpIdx: null};
}

const _isIOSandinApp = () => {
  // return !!window.navigator.userAgent.includes("iPad");
  return (!!window.navigator.userAgent.includes("iPad") || !!window.navigator.userAgent.includes("iPhone")) && !!window?.localStorage?.getItem("inApp");
}

const isIpadOS = () => {
  return navigator.maxTouchPoints &&
    navigator.maxTouchPoints > 2 &&
    /MacIntel/.test(navigator.platform);
}

const _isiPad = () => !!(window.navigator.userAgent.includes("iPad") || (navigator.maxTouchPoints && navigator.maxTouchPoints > 2 && /MacIntel/.test(navigator.platform)));

const _isApp = () => {
  // return !!window.navigator.userAgent.includes("iPad");
  return !!window?.localStorage?.getItem("inApp");
}

const _isMobile = () => !!((typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1));

const _isDesktop = () => !((typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1));

const _isSalesAgentView = () => !!(!!(_getUserPriorityRole() === "admin" && _getCookieValue("ADMIN_SALES_AGENT_VIEW")) || _getUserPriorityRole() === "sales agent");

const _getApiKeyAdminAgent = () => {
  let retval = false;
  if (_getUserPriorityRole && _getUserPriorityRole() && (_getUserPriorityRole() === "sales agent" || _getUserPriorityRole() === "admin")) {
    retval = _getUserPriorityRole() === "sales agent" ? "sales-agent" : "admin";
  }
  return retval;
};

const _removeURLParam = (key, sourceURL) => {
  var rtn = sourceURL.split("?")[0],
      param,
      params_arr = [],
      queryString = sourceURL.indexOf("?") !== -1 ? sourceURL.split("?")[1] : "";
  if (queryString !== "") {
      params_arr = queryString.split("&");
      for (var i = params_arr.length - 1; i >= 0; i -= 1) {
          param = params_arr[i].split("=")[0];
          if (param === key) {
              params_arr.splice(i, 1);
          }
      }
      if (params_arr.length) rtn = rtn + "?" + params_arr.join("&");
  }
  return rtn;
};

export {
  _buildFormData,
  _errorHandlerForms,
  _capitalizeString,
  _onChange,
  _onBlurFunc,
  _exportDateISO,
  _queryParamGenerator,
  _dateStringFormatted,
  _numberWithCommas,
  _getLsValues,
  _getBaseUrl,
  _axiosIntercept,
  _parseJwt,
  _getUserData,
  _setCookieValue,
  _getCookieValue,
  _getConfig,
  _getLogData,
  _getUserPriorityRole,
  _getRolePriority,
  _inView,
  _debounce,
  _lazyloadImages,
  _closeAlert,
  _errorAlert,
  _infiniteScrollListener,
  _infiniteLoad,
  _loadMore,
  _getApiResponseWithSearch,
  _postApiResponseWithSearch,
  _timedPopup,
  _buildParams,
  _findIdxById,
  _pageEnterTime,
  _pageExitTime,
  _isDevLink,
  _isQaLink,
  _universal_logger,
  _universal_logger_v2,
  _sanitizeWEevent,
  _setLsValues,
  _existAndGetLsValue,
  _getOrSetIpLocation,
  _isJsonString,
  _logBackendEvent,
  _isiPad,
  _isApp,
  _isIOSandinApp,
  _isMobile,
  _isDesktop,
  _isSalesAgentView,
  _getApiKeyAdminAgent,
  _removeURLParam
};
